.side-bar {
  height: 100%;
  position: fixed;
  top: 65px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.94);
  width: 250px;
}

.profile-picture img {
  position: absolute;
  top: 25px;
  border-radius: 200px;
  left: 50px;
  height: 150px;
}

.general-info {
  color: white;
  position: absolute;
  top: 200px;
  text-align: left;
  padding-left: 15px;
  line-height: 30px;
}

.general-info svg {
  margin-right: 10px;
}

.social-media {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.social-media svg {
  height: 30px;
  color: white;
}

@media screen and (max-width: 768px) {
  .side-bar {
    height: 80px;
    width: 100%;
  }

  .profile-picture img {
    border-radius: 60px;
    left: 25px;
    top: 10px;
    height: 60px;
  }

  .general-info {
    top: 20px;
    left: 100px;
    text-align: left;
    line-height: 20px;
    width: 100%;
  }

  .general-info hr {
    display: none;
  }

  .general-info .social-media {
    position: fixed;
    right: 25px;
    top: 75px;
    width: 200px;
    text-align: right;
  }
}
