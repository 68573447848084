.app {
  text-align: center;
}

.content {
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  width: calc(100% - 250px);
  float: right;
}

.section {
  width: 80%;
  padding: 25px;
  margin: 15px;
  font-size: 16pt;
  line-height: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 2px rgba(114, 114, 114, 0.94);
}

.sub-section {
  text-align: left;
}

.sub-heading {
  font-size: smaller;
  font-style: italic;
  font-weight: normal;
}

.title {
  font-size: smaller;
  font-weight: bold;
}

.sub-section svg {
  margin-right: 10px;
}

.sub-section .social-media {
  text-align: left;
}

.sub-section .social-media svg {
  height: 30px;
  color: black;
}

.section {
  scroll-margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding-top: 150px;
  }

  .section {
    scroll-margin-top: 100px;
  }
}
